<template>
<v-container class="pt-0 full-height">
  <v-row>
    <v-col>
      <h1 class="section-title">Conexión a impresora</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="4">
      <v-text-field outlined label="IP de la computadora a la que se va a conectar"
      color="secondary"
      v-model="ipAddress"></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-btn @click="connectToPrinter" color="secondary">Conectar</v-btn>
    </v-col>
  </v-row>
  <v-row class="pt-4">
    <v-col>
      <h1 class="section-title">B&uacute;squeda de productos</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="4">
      <v-select
        v-model="productsSearchType"
        :items="productsSearchTypes"
        @change="onProductsSearchTypeChanged"
        label="Tipo de búsqueda"
        outlined
      ></v-select>
    </v-col>
  </v-row>
  <v-row class="pt-4">
    <v-col>
      <h1 class="section-title">Carrito de compras</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="4">
      <v-switch
        v-model="showDiscountInLine"
        label="Mostrar descuento en línea"
        @change="onShowDiscountInLineChanged"
      ></v-switch>
    </v-col>
    <v-col cols="12" sm="4">
      <v-switch
        v-model="ignoreInventory"
        label="Ignorar inventario"
        @change="onShowIgnoreInventory"
      ></v-switch>
    </v-col>
  </v-row>
  <v-row class="pt-4">
    <v-col>
      <h1 class="section-title">Lector de c&oacute;digo de barras</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="4">
      <v-subheader class="px-0">
        Sensibilidad del lector: <span class="font-weight-bold pl-1">{{barcodeSensitivity}}</span>
      </v-subheader>
      <v-slider
        max="200"
        min="1"
        v-model="barcodeSensitivity"
        @change="onBarcodeSensitivityChanged"
      ></v-slider>
    </v-col>
  </v-row>
</v-container>
</template>

<script>

export default {
  name: 'configuration',
  data: () => ({
    amount: null,
    cashStartId: null,
    ipAddress: '127.0.0.1',
    productsSearchTypes: [
      { text: 'Remota', value: 'remote' },
      { text: 'Local', value: 'local' },
    ],
    productsSearchType: '',
    barcodeSensitivity: 100,
    showDiscountInLine: false,
    ignoreInventory: false,
  }),
  methods: {
    connectToPrinter() {
      if (this.ipAddress === '') {
        this.ipAddress = '127.0.0.1';
      }
      localStorage.setItem('printerIPAddress', this.ipAddress);
      this.$store.dispatch('setPrinterIPAddress', this.ipAddress);
    },
    onProductsSearchTypeChanged() {
      localStorage.setItem('productsSearchType', this.productsSearchType);
      this.$store.dispatch('setProductsSearchType', this.productsSearchType);
    },
    onBarcodeSensitivityChanged() {
      localStorage.setItem('barcodeSensitivity', this.barcodeSensitivity);
    },
    onShowDiscountInLineChanged() {
      localStorage.setItem('showDiscountInLine', this.showDiscountInLine ? '1' : '0');
      this.$store.dispatch('setShowDiscountInLine', this.showDiscountInLine);
    },
    onShowIgnoreInventory() {
      localStorage.setItem('ignoreInventory', this.ignoreInventory ? '1' : '0');
    },
  },
  mounted() {
    this.ipAddress = localStorage.getItem('printerIPAddress');
    this.productsSearchType = localStorage.getItem('productsSearchType');
    this.barcodeSensitivity = localStorage.getItem('barcodeSensitivity') ?? 100;

    const showDiscountInLineSetting = localStorage.getItem('showDiscountInLine') ?? '0';
    this.showDiscountInLine = showDiscountInLineSetting === '1';

    const showIgnoreInventory = localStorage.getItem('ignoreInventory') ?? '0';
    this.ignoreInventory = showIgnoreInventory === '1';
  },
};
</script>

<style scoped>
.section-title {
  font-weight: 300;
  font-size: 1.5rem;
  border-bottom: 1px black solid;
}
</style>
